<template>
    <div v-if="ready">
        <div class="extra-widget-frame">
            <div class="player">
                <div class="player-data">
                    <player-chip :player="activePlayer" :with-jail-marker="true"/>
                    {{ activePlayer.name }}
                </div>
                <span>| {{ formatBalance(activePlayer?.balance) }}</span>
            </div>

            <div v-if="!animationOngoing"
                 class="sector-tasks"
                 :class="{'sector-tasks--active': !!activeTaskOfActivePlayer}">

                <img v-if="activeSector?.image" :src="activeSector.image" alt="slot-logo"/>

                <div class="active-task" v-if="activeTaskOfActivePlayer">
                    <span>
                        {{ activeTaskOfActivePlayer.text }}
                    </span>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
    import {computed, onMounted, ref, watch} from "vue";
    import {useStore} from "vuex";
    import PlayerChip from "@components/games/slotopoly/PlayerChip.vue";
    import ImageAnimationContainer from "@components/games/slotopoly/ImageAnimationContainer.vue";

    const props = defineProps({
        publicToken: {
            type: String
        }
    })
    const store = useStore();

    const BOARD_SIZE = 28;


    const animContainer = ref(null); //templateRef

    const currentMoveId = ref(false)
    const animationOngoing = ref(false)
    const diceValue = ref(null)
    const winner = ref(null)


    const setToken = () => {
        localStorage.setItem('publicToken', props.publicToken ?? '')
    }

    const cyclicUpdateGame = async () => {
        setTimeout(() => {
            store.dispatch('games/getWidgetData')
                    .then(cyclicUpdateGame())
        }, 1000)
    }

    onMounted(() => {
        setToken()
        cyclicUpdateGame()
    })

    const activeGame = computed(() => store.getters['games/activeGame'])

    const ready = computed(() => !!activeGame.value?.id)


    const sectors = computed(() => activeGame.value?.meta?.board);
    const players = computed(() => activeGame.value?.meta?.players);
    const tasks = computed(() => activeGame.value?.meta?.tasks);
    const movements = computed(() => activeGame.value?.meta?.movements);

    const activePlayerIndex = computed(() => players.value?.findIndex(player => player.active) ?? 0)
    const activePlayer = computed(() => players.value[activePlayerIndex.value])
    const activePlayerId = computed(() => activePlayer.value?.id)
    const activePlayerPosition = computed(() => activePlayer.value?.position)

    const activeTaskOfActivePlayer = computed(() => tasks.value?.[activePlayerId.value])

    const activeSector = computed(() => sectors.value[activePlayerPosition.value])

    const showCenterAnimation = (delay = 3000) => {
        return new Promise((resolve) => {
            // animContainer.value.show();
            setTimeout(() => {
                // animContainer.value.hide();
                resolve();
            }, delay);
        });
    };

    const ANIMATION_CLOSE_OPEN_DELAY = 300
    const ZINA_ANIMATION_LENGTH = 7000
    const ONE_STEP_ANIMATION_LENGTH = 500

    const calculatePlayerPosition = (playerId) => {
        return movements.value?.filter(item => item.playerId === playerId)
                .reduce((sum, item) => sum + item.movement, 0) ?? 0;
    }

    const showAnimatedDiceValue = async (move) => {
        const random = move?.random
        diceValue.value = move.movement;
        animationOngoing.value = true;

        const delay = random
                ? Math.abs(diceValue.value) * ONE_STEP_ANIMATION_LENGTH
                : Math.abs(diceValue.value) * ONE_STEP_ANIMATION_LENGTH + ANIMATION_CLOSE_OPEN_DELAY * 2

        if (random) {
            await new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, ZINA_ANIMATION_LENGTH);
            })
        }


        await showCenterAnimation(delay)
        animationOngoing.value = false;
        diceValue.value = null


        if (calculatePlayerPosition(move.playerId) >= BOARD_SIZE) {
            winner.value = players.value.find(pl => pl.id === move.playerId)
            animContainer.value.show();
        }

    }
    const formatBalance = number => new Intl.NumberFormat('ru-RU').format(number);


    watch(
            () => movements.value,
            async (newMoves, oldMoves) => {
                if (newMoves.length > oldMoves.length) {
                    const newMove = newMoves.at(-1);

                    if (newMove?.id && newMove.id !== currentMoveId.value) {
                        currentMoveId.value = newMove.id;
                        animationOngoing.value = true;
                        await showAnimatedDiceValue(newMove);
                        animationOngoing.value = false;
                    }
                } else if (newMoves.length < oldMoves.length) {
                    winner.value = null
                    diceValue.value = null
                }
            },
            {immediate: true}
    );

</script>

<style lang="scss" scoped>
    .extra-widget-frame {
        width: 350px;
        height: 250px;
        background: rgba(144, 99, 207, 1);
        position: relative;
        overflow: hidden;

        .player {
            height: 50px;
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 16px;
            align-items: stretch;
            font-size: 2em;
            font-weight: bold;
            color: white;
            padding: 2%;
            position: absolute;
            top: 0;


            .player-data {
                display: flex;
                gap: 5%;
                align-items: stretch;

                > * {
                    width: 100%;
                }
            }
        }



        .sector-tasks {
            display: flex;
            justify-content: center;
            align-content: center;
            width: 100%;
            max-width: 100%;
            height: 200px;
            position: absolute;
            top: 50px;
            align-items: center;
            flex-direction: row;
            gap: 10px;

            img {
                object-fit: contain;
                max-height: 200px;
            }


            .active-task {
                display: flex;
                font-size: 1.8em;
                height: 100%;
                align-items: center;
                justify-content: flex-start;
                > * {
                    line-height: 1;
                    text-align: start;
                    font-weight: bold;
                    color: white;
                }
            }
        }

        .dice-value {
            display: flex;
            font-size: 10em;
            color: white;
            font-weight: bold;
            align-items: center;
            justify-content: center;
            height: 100%;
        }



        .winner-content {
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-size: 3em;
            font-weight: bolder;
        }
    }

</style>