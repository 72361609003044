<template>
    <div class="flex flex-column row-gap-3">
        <div class="flex justify-content-start gap-3 w-full p-2" v-for="link in links" :key="link.url">
            <InputText
                class="h-1rem w-6"
                type="text"
                readonly="true"
                @focus="selectText"
                :value="link.url"/>
            <Button
                class="p-button p-button-sm h-1rem"
                icon="pi pi-copy"
                @click="copyLink(link.url)"/>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';
    import router from "@/router.js";
    import store from '@/store/store.js';

    const props = defineProps({
        publicToken: String
    });

    const appUrl = import.meta.env.VITE_APP_URL ?? 'https://slotsstreaming.pro';
    const publicToken = computed(() => store.getters['user/me'].public_token);

    const links = computed(() => [
        {
            url: appUrl + router.resolve({ name: 'session-widget', query: { public_token: publicToken.value } }).href,
        },
        {
            url: appUrl + router.resolve({ name: 'slot-widget', query: { public_token: publicToken.value } }).href,
        },
    ] );

    const copyLink = async (url) => {
        try {
            await navigator.clipboard.writeText(url);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const selectText = (event) => {
        event.target.select();
    };
</script>
