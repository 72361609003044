<script setup>
    import {computed, onMounted, ref} from "vue";
    import SlotopolyBoard from "@components/games/slotopoly/SlotopolyBoard.vue";
    import ManageSector from "@components/games/slotopoly/ManageSector.vue";
    import Button from 'primevue/button';
    import Dialog from 'primevue/dialog';
    import InputText from 'primevue/inputtext';
    import {defaultSlotopolyBoard,} from "@components/games/slotopoly/defaultSlotopolyEntries.js";
    import {useStore} from "vuex";
    import {useSlotopolyStorage} from "@pages/games/slotopoly/useSlotopolyStorage";
    import ManagePlayers from "@components/games/slotopoly/ManagePlayers.vue";
    import SlotopolyGameController from "@components/games/slotopoly/SlotopolyGameController.vue";

    const store = useStore();

    const {templateTasks, addTask, saveTemplatedData} = useSlotopolyStorage()

    onMounted(() => {
        store.dispatch('games/initTemplateGameData')
        store.dispatch('mediaFiles/getAll')
    })

    const emit = defineEmits(['game:start', 'game:update', 'game:finish']);

    const boardTemplateRef = ref(null);

    const isEditMode = ref(true)

    const toggleEditMode = () => {
        isEditMode.value = !isEditMode.value
    }

    //TODO Remove this or replace with smth real
    const finishGame = () => {
        console.log("finishGame")
    }
    const setActiveGame = () => {
        console.log("setActiveGame")
    }
    const clearGameData = () => {
        console.log("clearGameData")
    }
    defineExpose({
        finishGame,
        setActiveGame,
        clearGameData
    })

    const gameData = computed(() => store.getters['games/activeGame']?.meta);
    // BOARD
    const sectors = computed(() => store.getters['games/activeGame']?.meta?.board ?? defaultSlotopolyBoard);
    const players = computed(() => store.getters['games/activeGame']?.meta?.players);
    // const lastMove = computed(() => store.getters['games/activeGame']?.meta?.lastMove);
    const tasks = computed(() => store.getters['games/activeGame']?.meta?.tasks);
    const movements = computed(() => store.getters['games/activeGame']?.meta?.movements);

    const isModalTasksOpened = ref(false)
    const isModalSectorOpened = ref(false)

    const editableSector = ref(null)

    const openTasksModal = () => {
        isModalTasksOpened.value = true
    }

    const selectSectorToEdit = (sector) => {
        editableSector.value = sector;
        isModalSectorOpened.value = true
    };

    const updateSector = () => {
        const sectorsNew = sectors.value.map(s => (s.id === editableSector.value.id ? editableSector.value : s))
        emit('game:update', {
            board: clearBoardPlayersBeforePost(sectorsNew),
            players: players.value,
            // lastMove: lastMove.value,
            tasks: tasks.value,
            movements: movements.value,
        })
        boardTemplateRef.value.clearEditableSector()
    }

    const updateGlobalState = () => {
        emit('game:update', {
            board: clearBoardPlayersBeforePost(sectors.value),
            players: players.value,
            // lastMove: lastMove.value,
            tasks: tasks.value,
            movements: movements.value,
        })
    }

    // PLAYERS
    const isModalPlayersOpened = ref(false)

    // because i suck in reactivity :(
    const clearBoardPlayersBeforePost = (board) => {
        return board.map((sector) => ({
            ...sector,
            players: [],
        }));
    };

    //TASKS
    const setActiveTask = (task) => {
        const updatedTasks = {
            ...tasks.value,
            ...task,
        };

        emit('game:update', {
            board: clearBoardPlayersBeforePost(sectors.value),
            players: players.value,
            // lastMove: lastMove.value,
            tasks: updatedTasks,
            movements: movements.value,
        })
    }

    //PLAYER MOVEMENTS
    const updateMovements = (movements) => {
        const lastMove = movements.at(-1);
        emit('game:update', {
            board: clearBoardPlayersBeforePost(sectors.value),
            players: players.value,
            // lastMove: lastMove.value,
            tasks: Object.fromEntries(
                    Object.entries(tasks.value).filter(([key]) => key !== lastMove.playerId)
            ),
            movements: movements,
        })
    }

    const superGame = () => {
        emit('game:update', {
            board: clearBoardPlayersBeforePost(sectors.value),
            players: players.value,
            tasks: tasks.value,
            movements: movements.value,
            superGame: true
        })
    }


</script>

<template>

    <!--    EDIT TASK LIST MODAL -->
    <Dialog v-model:visible="isModalTasksOpened" header="Manage Tasks" modal position="left" dismissableMask>
        <div class="content">
            <ol class="tasks-list">
                <li v-for="(task, index) in templateTasks" :key="task.id" class="task-item">
                    <InputText v-model="task.text" placeholder="Enter task..." size="small" class="input-task"/>
                    <Button
                            :disabled="editableSector?.tasks?.length >= 4"
                            label="Выбрать"
                            class="button-task p-button-sm"
                            @click="editableSector.tasks.push(task)"
                            size="small"/>
                </li>
                <Button label="Новое Задание" @click="addTask" size="small" class="button-task p-button-sm"/>

            </ol>

        </div>

        <template #footer>
            <Button label="Сохранить" @click="saveTemplatedData" size="small"/>
        </template>
    </Dialog>

    <!--    EDIT PLAYERS MODAL -->
    <Dialog v-model:visible="isModalPlayersOpened"
            header="Редактор игроков"
            modal position="left"
            dismissableMask
            @update:visible="updateGlobalState">
        <manage-players
                v-model="players"
        />
    </Dialog>


    <!--    EDIT SECTOR MODAL -->
    <Dialog v-model:visible="isModalSectorOpened"
            :header="`Редактирование сектора ${editableSector?.text}`"
            modal position="left"
            dismissableMask
            @update:visible="updateSector"
    >
        <manage-sector
                v-model="editableSector"
                @request:tasks="openTasksModal"
        />
    </Dialog>


    <div class="slotopoly-wrapper">
        <div class="actions">
            <div v-if="isEditMode">
                <Button label="Список заданий" @click="isModalTasksOpened = !isModalTasksOpened" size="small"/>
                <Button label="Редактор игроков" @click="isModalPlayersOpened = !isModalPlayersOpened" size="small"/>
            </div>

            <Button :label="isEditMode ? 'Начать игру' : 'Редактировать игру'"
                    class="p-button-sm p-button-success"
                    @click="toggleEditMode"
                    size="small"
            />

        </div>

        <slotopoly-board
                ref="boardTemplateRef"
                :game-data="gameData"
                :edit-mode="isEditMode"
                :is-widget="false"
                @click:sector="selectSectorToEdit"
                @click:task="setActiveTask"
        />
        <slotopoly-game-controller
                v-model="players"
                :sectors="sectors"
                :edit-mode="isEditMode"
                :movements="movements"
                @update:movements="updateMovements"
                @update:model-value="updateGlobalState"
                @super-game="superGame"
        />
    </div>
</template>

<style lang="scss" scoped>
    .slotopoly-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .actions, .actions > * {
        display: flex;
        flex-direction: row;
        gap: 8px
    }

    .tasks-list {
        padding-left: 0;
    }

    .task-item {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-bottom: 8px
    }

    .input-task, .button-task {
        padding: 4px
    }
</style>