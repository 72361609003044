<script setup>
    import {computed, defineExpose, onMounted, ref, watch} from "vue";
    import SectorPlayersLayer from "@components/games/slotopoly/SectorPlayersLayer.vue";
    import PlayerChip from "@components/games/slotopoly/PlayerChip.vue";
    import VideoAnimationContainer from "@components/games/slotopoly/VideoAnimationContainer.vue";
    import {videoAnimations} from "@public/video/videos.js"
    import ImageAnimationContainer from "@components/games/slotopoly/ImageAnimationContainer.vue";
    import {defaultSlotopolyBoard} from "@components/games/slotopoly/defaultSlotopolyEntries.js";
    import superigra from '../../../../assets/images/games/slotopoly/superigra.jpg'


    const BOARD_SIZE = 28

    const props = defineProps({
        gameData: {
            type: Object
        },

        editMode: {
            type: Boolean,
            default: true
        },

        isWidget: {
            type: Boolean,
            default: false
        },
    })


    const emit = defineEmits(['click:sector', 'click:task'])

    const videoPlayer = ref(null); //templateRef
    const animContainer = ref(null); //templateRef

    const boardCopy = ref(props?.gameData?.board ?? defaultSlotopolyBoard) //local copy

    const editableSector = ref(null) //on edit mode - not widget

    const currentMoveId = ref(null); //storage slot to prevent duplicate movements

    const animationOngoing = ref(false)

    const noRandomDiceResult = ref(null) //show value of manual dice or modifier
    const showSectorTasks = ref(false)

    const winner = ref(null)
    const isShowWinner = ref(false)


    const clearEditableSector = () => {
        editableSector.value = null
    }

    const activePlayerId = computed(() => props.gameData?.players?.find(player => player.active)?.id)

    const activeTaskOfActivePlayer = computed(() => props.gameData?.tasks?.[activePlayerId.value])

    const activeSector = computed(() => {
        if (animationOngoing.value) return null
        const playerPosition = calculatePlayerPosition(activePlayerId.value) ?? 0;
        if (boardCopy.value) return boardCopy.value[playerPosition]
    });

    const topPlayers = computed(() => {
        const players = props.gameData?.players || [];
        if (players.length <= 2) return players

        const topCount = Math.ceil(players.length / 2);
        return players.slice(0, topCount);
    });

    const bottomPlayers = computed(() => {
        const players = props.gameData?.players || [];
        if (players.length <= 2) return []
        const topCount = Math.ceil(players.length / 2);
        return players.slice(topCount);
    });

    const handleSectorClick = (sector) => {
        if (!props.editMode) return
        editableSector.value = sector
        emit('click:sector', sector)
    }

    const sectorClass = (sector) => {
        const position = sector?.position
        const editable = !!editableSector.value && sector.id === editableSector.value.id ? 'editable' : ''
        const active = !!activeSector.value && !props.editMode && sector.id === activeSector.value.id && !animationOngoing.value ? 'active' : ''
        return `sector ${position} ${active} ${editable}`
    }


    const playVideoAnimation = async (index) => {
        if (!props.isWidget) return
        try {
            await videoPlayer.value.playVideo(index);
        } catch (error) {
            console.error('Ошибка при воспроизведении:', error);
        }
    };

    const calculatePlayerPosition = (playerid) => {
        return props.gameData?.movements?.filter(item => item.playerId === playerid)
                .reduce((sum, item) => sum + item.movement, 0) ?? 0;
    }

    const movingPlayer = ref(null);

    const sectorMapping = {
        // Левая сторона
        0: {line: 'left', x: 0, y: 100},  // gridArea: 8 / 1
        1: {line: 'left', x: 0, y: 85.7}, // gridArea: 7 / 1
        2: {line: 'left', x: 0, y: 71.4}, // gridArea: 6 / 1
        3: {line: 'left', x: 0, y: 57.1}, // gridArea: 5 / 1
        4: {line: 'left', x: 0, y: 42.8}, // gridArea: 4 / 1
        5: {line: 'left', x: 0, y: 28.5}, // gridArea: 3 / 1
        6: {line: 'left', x: 0, y: 14.2}, // gridArea: 2 / 1
        7: {line: 'top', x: 0, y: 0},     // gridArea: 1 / 1

        // Верхняя сторона
        8: {line: 'top', x: 14.3, y: 0},  // gridArea: 1 / 2
        9: {line: 'top', x: 28.6, y: 0},  // gridArea: 1 / 3
        10: {line: 'top', x: 42.9, y: 0}, // gridArea: 1 / 4
        11: {line: 'top', x: 57.2, y: 0}, // gridArea: 1 / 5
        12: {line: 'top', x: 71.5, y: 0}, // gridArea: 1 / 6
        13: {line: 'top', x: 85.8, y: 0}, // gridArea: 1 / 7
        14: {line: 'right', x: 100, y: 0}, // gridArea: 1 / 8

        // Правая сторона
        15: {line: 'right', x: 100, y: 14.3}, // gridArea: 2 / 8
        16: {line: 'right', x: 100, y: 28.6}, // gridArea: 3 / 8
        17: {line: 'right', x: 100, y: 42.9}, // gridArea: 4 / 8
        18: {line: 'right', x: 100, y: 57.2}, // gridArea: 5 / 8
        19: {line: 'right', x: 100, y: 71.5}, // gridArea: 6 / 8
        20: {line: 'right', x: 100, y: 85.8}, // gridArea: 7 / 8
        21: {line: 'bottom', x: 100, y: 100}, // gridArea: 8 / 8

        // Нижняя сторона
        22: {line: 'bottom', x: 85.7, y: 100}, // gridArea: 8 / 7
        23: {line: 'bottom', x: 71.4, y: 100}, // gridArea: 8 / 6
        24: {line: 'bottom', x: 57.1, y: 100}, // gridArea: 8 / 5
        25: {line: 'bottom', x: 42.8, y: 100}, // gridArea: 8 / 4
        26: {line: 'bottom', x: 28.5, y: 100}, // gridArea: 8 / 3
        27: {line: 'bottom', x: 14.2, y: 100}, // gridArea: 8 / 2
        28: {line: 'left', x: 0, y: 100},     // gridArea: 8 / 1
    };


    const animateMovementToken = (startX, startY, endX, endY, duration = 600) => {
        return new Promise((resolve) => {
            const token = document.getElementById('movement-token');

            if (!token) {
                console.error("Movement token not found!");
                resolve();
                return;
            }

            const startTime = performance.now();

            const animate = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / duration, 1); // [0, 1]

                // Линейная интерполяция координат
                const currentX = startX + (endX - startX) * progress;
                const currentY = startY + (endY - startY) * progress;

                token.style.left = `${currentX}%`;
                token.style.top = `${currentY}%`;

                // Добавляем увеличение размера во время движения
                token.style.transform = `translate(-50%, -50%) scale(${progress < 1 ? 1.2 : 1})`;

                if (progress < 1) {
                    requestAnimationFrame(animate);
                } else {
                    // Сбрасываем масштаб после завершения анимации
                    token.style.transform = `translate(-50%, -50%) scale(1)`;
                    resolve(); // Завершаем анимацию
                }
            };

            requestAnimationFrame(animate);
        });
    };


    const playMoveAnimation = async (move) => {
        const playerInMoveId = move.playerId
        const player = props.gameData?.players?.find((pl) => pl.id === playerInMoveId)
        const newPlayerPosition = calculatePlayerPosition(playerInMoveId);
        movingPlayer.value = player;

        const isWinner = newPlayerPosition >= BOARD_SIZE;

        const extraSteps = newPlayerPosition - BOARD_SIZE

        const roll = move.movement;

        const steps = isWinner ? roll - extraSteps : roll;
        const prevPosition = newPlayerPosition - roll;
        const stepDirection = steps > 0 ? 1 : -1;

        // Удаляем игрока из текущего сектора
        if (boardCopy.value[prevPosition]) {
            boardCopy.value[prevPosition].players = boardCopy.value[prevPosition].players.filter(
                    (pl) => pl.id !== playerInMoveId
            );
        }
        for (let i = 0; i < Math.abs(steps); i++) {
            const currentSector = prevPosition + i * stepDirection;
            const nextSectorIndex = prevPosition + (i + 1) * stepDirection;

            const startX = sectorMapping[currentSector].x;
            const startY = sectorMapping[currentSector].y;
            const endX = sectorMapping[nextSectorIndex].x;
            const endY = sectorMapping[nextSectorIndex].y;

            // Анимация движения токена
            await animateMovementToken(startX, startY, endX, endY);
        }
        // Добавляем игрока в новый сектор

        if (boardCopy.value[newPlayerPosition]) {
            boardCopy.value[newPlayerPosition].players.push(player);
        }

        movingPlayer.value = null;
        if (isWinner) {
            boardCopy.value.at(-1)?.players.filter((pl) => pl.id !== playerInMoveId)
            boardCopy.value[0]?.players.push(player)
            showSectorTasks.value = false
            noRandomDiceResult.value = null
            isShowWinner.value = true
            winner.value = player
            animContainer.value.show();
        }
    };


    const rollBackMovement = (move) => {

        const playerInMoveId = move.playerId
        const player = props.gameData?.players?.find((pl) => pl.id === playerInMoveId)
        const prevPlayerPosition = calculatePlayerPosition(playerInMoveId)
        const currentSector = !!winner.value ? boardCopy.value[0] : boardCopy.value[prevPlayerPosition + move.movement];
        if (currentSector) {
            currentSector.players = currentSector?.players.filter(
                    (pl) => pl.id !== playerInMoveId
            );
        }
        const nextSector = boardCopy.value[prevPlayerPosition];
        const alreadyHasPlayer = nextSector?.players.some((pl) => pl.id === playerInMoveId);
        if (nextSector && !alreadyHasPlayer) {
            nextSector.players.push(player);
        }

        isShowWinner.value = false
        winner.value = null
    }


    const updatePlayersOnBoard = () => {
        boardCopy.value.forEach((sector) => {
            sector.players = [];
        });

        props.gameData?.players?.forEach((player) => {
            const playerPosition = calculatePlayerPosition(player.id);
            const sector = boardCopy.value[playerPosition ?? 0];
            const alreadyHasPlayer = sector?.players.some((pl) => pl.id === player.id);
            if (sector && !alreadyHasPlayer) {
                sector.players.push(player);
            }
        });
    };


    defineExpose({
        clearEditableSector,
    })


    const showCenterAnimation = (delay = 3_000_000) => {
        return new Promise((resolve) => {
            animContainer.value.show();
            setTimeout(() => {
                animContainer.value.hide();
                resolve();
            }, delay);
        });
    };

    const showNotRandomDiceResult = async (value) => {
        if (!props.isWidget) return
        noRandomDiceResult.value = value
        await showCenterAnimation(2000)
        noRandomDiceResult.value = null
    }

    const showSectorInfoWithTasks = () => {
        noRandomDiceResult.value = null
        showSectorTasks.value = true
        animContainer.value.show();
    }

    const playTargetSectorAnimation = async (newMove) => {
        // if (!props.isWidget) return
        const playerPosition = calculatePlayerPosition(newMove.playerId)

        const corners = ['corner_1', 'corner_2', 'corner_3', 'corner_4'];
        const newPositionSectorId = boardCopy.value[playerPosition]?.id
        const modifier = boardCopy.value[playerPosition]?.modifier
        const whichCorner = corners.findIndex(corner => corner === newPositionSectorId)
        if (whichCorner > 0) {
            await playVideoAnimation(whichCorner + 5) // 5 - it's a dice sides.length as offset
        } else if (whichCorner < 0) {
            if (!!modifier) {
                await showNotRandomDiceResult(modifier)
            } else {
                showSectorInfoWithTasks()
            }
        }

    };

    const hideSectorTasks = () => {
        if (!props.isWidget) return
        setTimeout(() => {
            showSectorTasks.value = false
            animContainer.value.hide();
        }, 5000)
    }


    const onTaskClick = (task) => {
        if (animationOngoing.value) return
        emit('click:task', {[activePlayerId.value]: task})

        setTimeout(() => {
            showSectorTasks.value = false
            animContainer.value.hide();
        }, 3000)
    }


    const handlePlaybackComplete = () => {
        console.log('Видео завершило воспроизведение');
    };

    onMounted(() => {
        const token = document.querySelector('.movement-token');
        if (token) {
            const startSector = sectorMapping[0]; // Координаты стартового сектора (нижний левый угол)
            token.style.left = `${startSector.x}%`;
            token.style.top = `${startSector.y}%`;
        }
    });

    // Watch for new player (or remove)
    watch(
            () => props.gameData?.players?.length,
            (newLength, oldLength) => {
                if (newLength !== oldLength) {
                    updatePlayersOnBoard();
                }
            },
            {immediate: true}
    );
    // Watch for active Player Change
    watch(
            () => props.gameData?.players,
            (newPlayers, oldPlayers) => {
                const activeOld = oldPlayers?.findIndex(player => player.active)
                const activeNew = newPlayers?.findIndex(player => player.active)
                if (activeOld !== activeNew) {
                    updatePlayersOnBoard();
                }

            },
            {immediate: true, deep: true}
    );

    //hide sector tasks popup after 3 sec
    watch(
            () => activeTaskOfActivePlayer.value,
            (newTask, oldTask) => {
                if (!!newTask?.id && newTask?.id !== oldTask?.id) hideSectorTasks()
            }
    )

    watch(
            () => props.gameData?.superGame,
            (newValue, oldValue) => {
                if (!!newValue) {
                    console.log("hide winner board")
                    isShowWinner.value = false
                    animContainer.value.hide();
                }
            }
    )

    //watch for new movements or movement rollback
    watch(
            () => props.gameData?.movements,
            async (newMoves, oldMoves) => {
                if (newMoves?.length > oldMoves?.length) {
                    const newMove = newMoves.at(-1);

                    if (newMove?.id && newMove.id !== currentMoveId.value) {
                        currentMoveId.value = newMove.id;
                        animationOngoing.value = true;
                        if (newMove.random) {
                            await playVideoAnimation(newMove.movement - 1);
                        } else {
                            await showNotRandomDiceResult(newMove.movement);
                        }

                        await playMoveAnimation(newMove);
                        animationOngoing.value = false;
                        await playTargetSectorAnimation(newMove);
                    }
                } else if (newMoves?.length < oldMoves?.length) {

                    const deletedMove = oldMoves.at(-1);
                    currentMoveId.value = deletedMove.id;
                    rollBackMovement(deletedMove);
                }
            },
            {immediate: true}
    );

    const isAllPlayersHasLeftStart = computed(() => {
        return props.gameData?.players?.every(player => player.position > 0)
    })

    const formatBalance = number => new Intl.NumberFormat('ru-RU').format(number);

    const sectorVariableImage = (sector) => {
        if(sector.id === 'corner_1' && isAllPlayersHasLeftStart.value) {
            return superigra
        } else {}
        return sector.image
    }


</script>

<template>
    <div class="board" :class="{'widget-view': isWidget}">


        <div class="grid" :class="{ 'has-active-sector': !!editableSector || !!activeSector}">

            <!--    слой анимации перемещения фишки игрока-->
            <div class="movement-layer">
                <div id="movement-token">
                    <player-chip v-if="movingPlayer" :player="movingPlayer" :size="isWidget ? 'large' : 'normal'"/>
                </div>
            </div>

            <!--            игровое поле-->
            <div v-for="sector in boardCopy"
                 :key="sector?.text"
                 :class="sectorClass(sector)"
                 :style="{ gridArea: sector.gridArea }"
                 @click="handleSectorClick(sector)"
            >

                <div class="sector-content">
                    <img :src="sectorVariableImage(sector)" alt="" class="sector-image">
                </div>

                <sector-players-layer
                        v-if="sector.players?.length > 0"
                        :sector-players="sector.players"
                        :all-players="gameData?.players"
                        :is-widget="isWidget"
                        :key="sector.text"
                />
            </div>

            <!--            центральный контент: анимация "зины", задания, победитель-->
            <div class="center" :style="`--font-size: ${isWidget ? '2.5em' : '1.5em'}`">

                <div class="stats-bar top-bar"
                     :style="{ justifyContent: topPlayers.length === 1 ? 'center' : 'space-between' }">
                    <div v-for="player in topPlayers" class="player-stats">
                        <player-chip :player="player" :size="isWidget ? 'large' : 'normal'" :with-jail-marker="true"/>
                        <span>{{ player.name }} </span>
                        <span class="balance">
                            | {{ formatBalance(player.balance) }}
                        </span>
                    </div>
                </div>


                <div class="center-animation-container">
                    <video-animation-container ref="videoPlayer"
                                               class="video-animation-container"
                                               :videos="videoAnimations"
                                               @playback-complete="handlePlaybackComplete"/>

                    <image-animation-container ref="animContainer" class="image-animation-container">

                        <div class="dice-throw-wrapper" v-if="noRandomDiceResult">
                            {{ noRandomDiceResult }}
                        </div>

                        <div v-else-if="showSectorTasks"
                             :class="['sector-slot-tasks-wrapper',{'logo-only': !activeSector?.tasks?.length}]">
                            <div class="sector-slot-logo">
                                <img v-if="activeSector?.image" :src="activeSector.image" alt="slot-logo"/>
                            </div>

                            <div class="sector-slot-tasks" v-if="activeSector?.tasks">
                                <span v-for="task in activeSector?.tasks"
                                      :key="task.id"
                                      :class="['task', {'selected': activeTaskOfActivePlayer?.id === task.id}]"
                                      role="button"
                                      @click="onTaskClick(task)">
                                    {{ task.text }}
                                    <br>
                                </span>
                            </div>
                        </div>

                        <div class="winner-wrapper" v-if="isShowWinner">
                            <span>ПОБЕДИТЕЛЬ:</span>
                            <span class="winner-name">
                                {{ winner.name }}
                                <player-chip :player="winner"/>
                            </span>
                            <span>Баланс: {{ winner.balance }}</span>
                        </div>

                    </image-animation-container>
                </div>


                <div class="stats-bar bottom-bar"
                     :style="{ justifyContent: bottomPlayers.length === 1 ? 'center' : 'space-between' }">
                    <div v-for="player in bottomPlayers" class="player-stats">
                        <player-chip :player="player" :size="isWidget ? 'large' : 'normal'" :with-jail-marker="true"/>
                        <span>{{ player.name }} </span>
                        <span class="balance">
                            | {{ formatBalance(player.balance) }}
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<style lang="scss" scoped>
    .board {
        max-width: 50vw;
        aspect-ratio: 16/11.5;
        padding: 2%;
        position: relative;

        &.widget-view {
            max-height: 95vh;
            max-width: 100%
        }
    }


    .grid {
        display: grid;
        grid-template-rows: repeat(8, 1fr);
        grid-template-columns: repeat(8, 1fr);
        gap: 2px;
        position: relative;
        margin: 0;

        .movement-layer {
            position: absolute;

            top: 6%;
            left: 6%;
            width: 89%;
            height: 89%;
            z-index: 20;
            pointer-events: none;
            padding: 5%;

            #movement-token {
                position: absolute;
                transform: translate(-50%, -50%) scale(1);
                transition: transform 0.6s ease-in-out;
                transform-origin: center center;
            }
        }


        .sector {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            aspect-ratio: 16 / 11.5;
            position: relative;
            cursor: pointer;
            background-color: rgb(144, 99, 207);


            //TODO replace with provided real image for SUPERIGRA

            //&.bottom-left {
            //    background-size: cover;
            //    background-image: url('../../../../assets/images/games/slotopoly/superigra.jpg');
            //}

            .sector-content {
                z-index: 1;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;


                .sector-image {
                    max-height: 100%;
                    max-width: 100%;
                    object-fit: cover;
                    height: 100%;
                }
            }
        }

        &.has-active-sector {
            .sector.active {
                transform: scale(1.3);
                z-index: 6;
                box-shadow: 0 0 0 2px white;
            }
        }

        .center {
            grid-area: 2 / 2 / span 6 / span 6;
            display: flex;
            background-color: transparent;
            border: 2px solid #000;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .center-animation-container {
                position: relative;
                width: 100%;
                height: 100%;
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                &::before {
                    content: '';
                    display: block;
                    padding-top: calc(9 / 16 * 100% + 2px);
                    pointer-events: none;
                }

                .video-animation-container,
                .image-animation-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                }


                .dice-throw-wrapper {
                    display: flex;
                    font-size: 33em;
                    color: white;
                    font-weight: bold;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }

                .sector-slot-tasks-wrapper {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: row;
                    gap: 10px;
                    z-index: 3;

                    .sector-slot-logo {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;

                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &.logo-only {
                        justify-content: center;
                        gap: 0;

                        .sector-slot-logo {
                            max-width: 100%;
                        }
                    }

                    .sector-slot-tasks {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        height: 100%;
                        gap: 6px;

                        .task {
                            font-size: var(--font-size);
                            cursor: pointer;
                            color: white;
                            border: 2px solid transparent;
                            font-weight: bold;
                            line-height: 1.2;

                            &:hover {
                                border: 2px solid white
                            }

                            &.selected {
                                color: gold;
                                font-weight: bolder;
                            }
                        }
                    }
                }

                .winner-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    color: white;
                    font-size: calc(var(--font-size) * 1.7);
                    font-weight: bolder;
                    position: absolute;
                    top: 0;
                    left: 0;
                    gap: 16px;

                    .winner-name {
                        display: flex;
                        align-items: center;
                        gap: 16px;
                    }
                }
            }


            .stats-bar {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 5px 50px;
                height: 10%;
                flex-shrink: 0; /* Запрет на сжатие */
                max-height: 10%;


                .player-stats {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;
                    font-size: var(--font-size);
                    font-weight: bold;
                }

            }

        }
    }

</style>