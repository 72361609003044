<script setup>
    import Avatar from "primevue/avatar";

    const props = defineProps(['player', 'size', 'withJailMarker']);

</script>

<template>
    <div class="player-avatar-container">
        <Avatar
                :image="player.image ?? ''"
                :icon="player.image ? null : 'pi pi-user'"
                :size="size ?? 'normal'"
                shape="circle"
                class="player-ava"
                :style="`background-color: #${player.color}; margin: 0 !important;`"
                :class="{ 'in-jail': player.cooldown > 0 && withJailMarker }"
        />
    </div>
</template>


<style scoped lang="scss">
    .player-avatar-container {
        position: relative;
        display: inline-flex; /* Для корректного позиционирования */
        width: inherit; /* Размер контейнера */
        height: inherit;


        .player-ava {
            position: relative;
            z-index: 1; /* Аватарка ниже псевдоэлемента */
            aspect-ratio: 1;

            &.in-jail::before {
                content: "#";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 3rem;
                line-height: 1;
                color: #69646b;
                z-index: 2;
                pointer-events: none;
                text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
            }
        }
    }

</style>
