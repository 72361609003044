<template>
    <div class="animated-image-container" :class="{'animated-image-container--is-visible': isVisible}">
        <div class="animated-content" :class="{'animated-content--is-visible': isVisible}">
            <slot/>
        </div>
    </div>
</template>

<script setup>
    import {ref} from 'vue';

    const isVisible = ref(false);

    const show = () => {
        isVisible.value = true;
    };

    const hide = () => {
        isVisible.value = false;
    };

    defineExpose({
        show,
        hide
    });
</script>

<style scoped lang="scss">
    .animated-image-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        background: rgba(0, 0, 0, 0);
        padding: 20px;

        &--is-visible {
            background: rgba(0, 0, 0, 0.4);
            transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
        }

    }

    .animated-content {
        width: 100%;
        height: 100%;
        position: relative;
        transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
        transform-origin: center center;
        pointer-events: auto;
        background-image: url('../../../../assets/images/games/slotopoly/sector_bg.png');
        background-size: cover;
        border: 5px solid rgb(144, 99, 207);
        transform: scale(0);
        z-index: 3;

        &--is-visible {
            transform: scale(1);
        }

    }

</style>