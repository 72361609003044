import {v4 as uuidv4} from 'uuid'

export const defaultSlotopolyBoard = [
        {
            "id": "corner_1",
            "tasks": [],
            "image": "https:\/\/streamwidget.online\/storage\/87\/start_finish.jpg",
            "modifier": null,
            "players": [],
            "text": "Start",
            "gridArea": "8 \/ 1",
            "position": "bottom-left"
        }, {
            "id": "sector_1",
            "tasks": [{
                "id": "8d2f54a6-c769-4f2b-b11d-8c9c4c6a545d",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 3.2k"
            }, {
                "id": "1c80ad20-193b-4eab-be19-7b7398b59471",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 9.6k"
            }, {
                "id": "e28fd25e-10b5-4461-99d0-01c6aac161b5",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 40k"
            }, {
                "id": "e1410bd4-e65e-48f1-9cfc-e7cb4e3d4e97",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 200. \u0414\u043e\u043f.\u0437\u0430\u0434\u0430\u043d\u0438\u0435 - \u043f\u043e\u0439\u043c\u0430\u0442\u044c wild \u043d\u0430 2-3-4-5 \u0431\u0430\u0440\u0430\u0431\u0430\u043d\u0430\u0445 \u0437\u0430 \u0441\u043f\u0438\u043d \u0438\u043b\u0438 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/92\/background-(2).png",
            "modifier": null,
            "players": [],
            "text": "Sector 1",
            "gridArea": "7 \/ 1",
            "position": "left"
        }, {
            "id": "sector_2",
            "tasks": [{
                "id": "a9f59427-0fc6-41d5-8c1e-c60f99965121",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 4k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 12k \u0438\u043b\u0438 \u0422\u041e\u041f \u0440\u0430\u043d\u0434\u043e\u043c\u043a\u0430 \u0437\u0430 14k"
            }, {
                "id": "86f2f014-804e-48a6-8847-5967300e47f9",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 10\u043a \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 36k \u0438\u043b\u0438 \u0422\u041e\u041f \u0440\u0430\u043d\u0434\u043e\u043c\u043a\u0430 \u0437\u0430 35k"
            }, {
                "id": "286f4f50-4003-4530-ad0c-57ec723bce99",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 40k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 48k"
            }, {
                "id": "3c1fe013-61dc-4e64-b264-c2df96eab485",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 200 \u0438\u043b\u0438 100 spins \u043f\u043e 250 \u0441 \u0448\u0430\u043d\u0441\u043e\u043c. \u0414\u043e\u043f. \u0441\u044b\u0433\u0440\u0430\u0432\u0448\u0430\u0439 \u0445250 \u0437\u0430 spin \u0438\u043b\u0438 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/93\/background-(3).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 2",
            "gridArea": "6 \/ 1",
            "position": "left"
        }, {
            "id": "sector_3",
            "tasks": [],
            "image": "https:\/\/streamwidget.online\/storage\/84\/00_\u0432\u043f\u0435\u0440\u0435\u0434.jpg",
            "modifier": "+2",
            "players": [],
            "text": "Sector 3",
            "gridArea": "5 \/ 1",
            "position": "left"
        }, {
            "id": "sector_4",
            "tasks": [{
                "id": "f74749e3-cbe8-4b81-b20c-340862e7003d",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 2 BONUS \u043f\u043e 4k  \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 10k"
            }, {
                "id": "5216475d-4a1b-4ae2-ac28-cdf90ca6bdb3",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 10k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u043f\u043e 30k"
            }, {
                "id": "15584ebf-2d23-47c6-822c-e0191b44615a",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 40k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 40k"
            }, {
                "id": "d4735056-fa2b-46b8-b5df-52e05f7074af",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 200 \u0438\u043b\u0438 125 spins \u0441 \u0448\u0430\u043d\u0441\u043e\u043c \u043f\u043e 180. \u0414\u043e\u043f. \u043c\u043e\u043d\u0435\u0442\u0430 \u0445500 \u0438\u043b\u0438 \u0437\u043e\u043b\u043e\u0442\u043e\u0439 \u043a\u043b\u0435\u0432\u0435\u0440 \u0437\u0430 \u0441\u043f\u0438\u043d \u0438\u043b\u0438 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/94\/background-(4).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 4",
            "gridArea": "4 \/ 1",
            "position": "left"
        }, {
            "id": "sector_5",
            "tasks": [{
                "id": "ded632cc-38c7-4015-a565-dec21e8c48dc",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 3.2k \u0438\u043b\u0438 \u0440\u0430\u043d\u0434\u043e\u043c\u043a\u0430 \u0437\u0430 9.28k"
            }, {
                "id": "fd7632ab-f9cc-4bb0-99e9-d5f73940058d",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 9.6k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 20k \u0438\u043b\u0438 \u0440\u0430\u043d\u0434\u043e\u043c\u043a\u0430 \u0437\u0430 32.48k"
            }, {
                "id": "ed82459d-80fc-4e04-9f87-522ab77f5729",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 2 BONUS \u043f\u043e 19.2k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 40k"
            }, {
                "id": "98f20557-81e0-422f-861c-6defc6c717f3",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 200. \u0414\u043e\u043f. BONUS 15 \u0441\u043f\u0438\u043d\u043e\u0432 \u0438\u043b\u0438 \u0443\u043c\u043d\u043e\u0436\u0435\u043d\u0438\u0435 \u04451000 \u043d\u0430 \u043b\u044e\u0431\u043e\u043c \u0441\u0438\u043c\u0432\u043e\u043b\u0435 \u0432 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/95\/background-(5).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 5",
            "gridArea": "3 \/ 1",
            "position": "left"
        }, {
            "id": "sector_6",
            "tasks": [],
            "image": "https:\/\/streamwidget.online\/storage\/85\/00_\u043d\u0430\u0437\u0430\u0434.jpg",
            "modifier": "-2",
            "players": [],
            "text": "Sector 6",
            "gridArea": "2 \/ 1",
            "position": "left"
        }, {
            "id": "corner_2",
            "tasks": [],
            "image": "https:\/\/streamwidget.online\/storage\/89\/prize_bg.jpg",
            "modifier": null,
            "players": [],
            "text": "Prize",
            "gridArea": "1 \/ 1",
            "position": "top-left"
        }, {
            "id": "sector_7",
            "tasks": [{
                "id": "f862ebfc-c863-471d-a483-a75c44743c4d",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 3.2k  \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 8k"
            }, {
                "id": "2bc0146c-6a15-4026-aec3-e4d64fd3768e",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 9.6k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 32k"
            }, {
                "id": "67f8bf56-479a-44d2-8f94-e51c0688b9e8",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 48k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 48k"
            }, {
                "id": "885af892-22ee-417e-a278-011b584b9f56",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 130 spins \u043f\u043e 160. \u0414\u043e\u043f. \u04451000 \u0437\u0430 spins \u0438\u043b\u0438 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/96\/background-(6).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 7",
            "gridArea": "1 \/ 2",
            "position": "top"
        }, {
            "id": "sector_8",
            "tasks": [{
                "id": "f0e8529a-8b44-4e96-a217-58849420d6f5",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 3k"
            }, {
                "id": "88369a7c-8d7f-46df-8f8a-b8d978b9f81e",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 10k"
            }, {
                "id": "4276fc8b-c017-4efa-979d-cec53e83af10",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 40k"
            }, {
                "id": "5b8bd5e3-5986-4add-9408-cf575a5faa3c",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 200 \u0438\u043b\u0438 100 spins \u0441 \u0448\u0430\u043d\u0441\u043e\u043c \u043f\u043e 216. \u0414\u043e\u043f. HOTMODE \u0432 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/97\/background-(7).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 8",
            "gridArea": "1 \/ 3",
            "position": "top"
        }, {
            "id": "sector_9",
            "tasks": [],
            "image": "https:\/\/streamwidget.online\/storage\/84\/00_\u0432\u043f\u0435\u0440\u0435\u0434.jpg",
            "modifier": "+2",
            "players": [],
            "text": "Sector 9",
            "gridArea": "1 \/ 4",
            "position": "top"
        }, {
            "id": "sector_10",
            "tasks": [{
                "id": "5e8cedc2-38c4-4cd7-891c-0813710abbcb",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 2 BONUS \u043f\u043e 6k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 10k \u0438\u043b\u0438 \u0440\u0430\u043d\u0434\u043e\u043c\u043a\u0430 \u0437\u0430 10.8k"
            }, {
                "id": "a1e11d49-f6b3-4d79-a604-f17d3575461a",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 10k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 30k \u0438\u043b\u0438 \u0422\u041e\u041f \u0440\u0430\u043d\u0434\u043e\u043c\u043a\u0430 \u0437\u0430 36k"
            }, {
                "id": "6de2c10e-2d85-4855-b7dd-4384909b5cf1",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 2 BONUS \u043f\u043e 20k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 50k"
            }, {
                "id": "f99bf328-7ca1-4d45-b702-80c6fc98d4d4",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 200 \u0438\u043b\u0438 100 \u0441\u043f\u0438\u043d\u043e\u0432 \u043f\u043e 250 \u0441 \u0448\u0430\u043d\u0441\u043e\u043c. \u0414\u043e\u043f. 2 \u0441\u044b\u0433\u0440\u0430\u0432\u0448\u0438\u0445 \u043e\u0434\u043d\u043e\u0432\u0440\u0435\u043c\u0435\u043d\u043d\u043e \u0437\u043e\u043b\u043e\u0442\u044b\u0445 \u043c\u043e\u043d\u0435\u0442\u044b \u0437\u0430 spin \u0438\u043b\u0438 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/98\/background-(8).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 10",
            "gridArea": "1 \/ 5",
            "position": "top"
        }, {
            "id": "sector_11",
            "tasks": [{
                "id": "f74749e3-cbe8-4b81-b20c-340862e7003d",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 2 BONUS \u043f\u043e 4k  \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 10k"
            }, {
                "id": "5216475d-4a1b-4ae2-ac28-cdf90ca6bdb3",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 10k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u043f\u043e 30k"
            }, {
                "id": "15584ebf-2d23-47c6-822c-e0191b44615a",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 40k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 40k"
            }, {
                "id": "d4735056-fa2b-46b8-b5df-52e05f7074af",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 200 \u0438\u043b\u0438 125 spins \u0441 \u0448\u0430\u043d\u0441\u043e\u043c \u043f\u043e 180. \u0414\u043e\u043f. \u043c\u043e\u043d\u0435\u0442\u0430 \u0445500 \u0438\u043b\u0438 \u0437\u043e\u043b\u043e\u0442\u043e\u0439 \u043a\u043b\u0435\u0432\u0435\u0440 \u0437\u0430 \u0441\u043f\u0438\u043d \u0438\u043b\u0438 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/99\/background-(9).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 11",
            "gridArea": "1 \/ 6",
            "position": "top"
        }, {
            "id": "sector_12",
            "tasks": [{
                "id": "8d2f54a6-c769-4f2b-b11d-8c9c4c6a545d",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 3.2k"
            }, {
                "id": "1c80ad20-193b-4eab-be19-7b7398b59471",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 9.6k"
            }, {
                "id": "e28fd25e-10b5-4461-99d0-01c6aac161b5",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 40k"
            }, {
                "id": "885af892-22ee-417e-a278-011b584b9f56",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 130 spins \u043f\u043e 160. \u0414\u043e\u043f. \u04451000 \u0437\u0430 spins \u0438\u043b\u0438 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/100\/background-(10).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 12",
            "gridArea": "1 \/ 7",
            "position": "top"
        }, {
            "id": "corner_3",
            "tasks": [],
            "image": "https:\/\/streamwidget.online\/storage\/88\/pump_bg.jpg",
            "modifier": null,
            "players": [],
            "text": "Percent",
            "gridArea": "1 \/ 8",
            "position": "top-right"
        }, {
            "id": "sector_13",
            "tasks": [{
                "id": "d201eed2-2259-47b7-a6f3-576dbf9433a7",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 3.75k \u043d\u0430 2-\u043c \u0431\u0430\u0440\u0430\u0431\u0430\u043d\u0435 \u0438\u043b\u0438 2 \u043f\u043e 5.4k \u043d\u0430 3-\u043c \u0438\u043b\u0438 1 \u0437\u0430 10.1k \u043d\u0430 4-\u043c"
            }, {
                "id": "013c3c92-9f20-4024-8892-9c2f9c766923",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 10k \u043d\u0430 2-\u043c \u0431\u0430\u0440\u0430\u0431\u0430\u043d\u0435 \u0438\u043b\u0438 2 \u043f\u043e 14.4k \u043d\u0430 3-\u043c \u0438\u043b\u0438 1 \u0437\u0430 30.3k \u043d\u0430 4-\u043c"
            }, {
                "id": "f1d6cddc-31c1-44df-b42d-f1f3f0d03992",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 2 BONUS \u043f\u043e 20k \u043d\u0430 2-\u043c \u0431\u0430\u0440\u0430\u0431\u0430\u043d\u0435 \u0438\u043b\u0438 1 \u0437\u0430 36k \u043d\u0430 3-\u043c \u0438\u043b\u0438 1 \u0437\u0430 40.4k \u043d\u0430 4-\u043c"
            }, {
                "id": "8475f617-95a4-4c39-aabc-b2457ea6f442",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 200. \u0414\u043e\u043f. 3 \u0443\u0441\u043f\u0435\u0448\u043d\u044b\u0445 \u0443\u0434\u0432\u043e\u0435\u043d\u0438\u044f \u043f\u0440\u0438\u0437\u043e\u0432\u043e\u0439 \u043f\u043e\u0434\u0440\u044f\u0434"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/101\/background-(11).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 13",
            "gridArea": "2 \/ 8",
            "position": "right"
        }, {
            "id": "sector_14",
            "tasks": [{
                "id": "19d12f6a-e542-40a9-acea-a0c4563dc3f7",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c  3 BONUS \u043f\u043e 3.55k"
            }, {
                "id": "bf6f99fc-32e9-4981-ba63-1d609a57eb8b",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 2 BONUS \u043f\u043e 17.75k"
            }, {
                "id": "08c270c0-031e-4db8-ba6e-142c0794f338",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 35.5k"
            }, {
                "id": "417bc4f0-7a9f-4c35-87b2-18a159a59840",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 1 \u043b\u0438\u043d\u0438\u0438 (250) \u0438\u043b\u0438 100 spins \u043f\u043e 5 \u043b\u0438\u043d\u0438\u0439 (250) \u0438\u043b\u0438 100 spins \u043f\u043e 10 \u043b\u0438\u043d\u0438\u0439 (200). \u0414\u043e\u043f. \u0441\u044b\u0433\u0440\u0430\u0432\u0448\u0430\u044f \u043f\u043e\u043b\u044f\u043d\u0430 \u043b\u044e\u0431\u044b\u0445 \u043a\u0430\u0440\u0442\u0438\u043d\u043e\u043a \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/102\/background-(12).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 14",
            "gridArea": "3 \/ 8",
            "position": "right"
        }, {
            "id": "sector_15",
            "tasks": [],
            "image": "https:\/\/streamwidget.online\/storage\/84\/00_\u0432\u043f\u0435\u0440\u0435\u0434.jpg",
            "modifier": "+3",
            "players": [],
            "text": "Sector 15",
            "gridArea": "4 \/ 8",
            "position": "right"
        }, {
            "id": "sector_16",
            "tasks": [{
                "id": "28f2e870-eb6c-4356-9c2c-c8ccbb9ec911",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 2 BONUS \u043f\u043e 4k  \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 8k \u0438\u043b\u0438 Best Of Bonus \u0437\u0430 10k"
            }, {
                "id": "4b0f05ba-1f2c-40f1-affb-5a2a80ed4dfb",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 10k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u043f\u043e 28k \u0438\u043b\u0438 Best Of Bonus \u0437\u0430 30k"
            }, {
                "id": "20e9dd90-4091-48ee-bf4e-468e590bc10d",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 40k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 40k \u0438\u043b\u0438 Best Of Bonus \u0437\u0430 40k"
            }, {
                "id": "db4a4297-a54a-4171-a8ce-ecbeb68ecd28",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 200 \u0438\u043b\u0438 100 spins \u0441 \u0448\u0430\u043d\u0441\u043e\u043c \u043f\u043e 200. \u0414\u043e\u043f. 4 \u0432\u0438\u043b\u0434\u0430 \u043e\u0434\u043d\u043e\u0432\u0440\u0435\u043c\u0435\u043d\u043d\u043e \u0437\u0430 spin \u0438\u043b\u0438 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/103\/background-(13).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 16",
            "gridArea": "5 \/ 8",
            "position": "right"
        }, {
            "id": "sector_17",
            "tasks": [{
                "id": "d32f4970-6c33-42b3-92d0-fb5d1a9004cd",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 2 BONUS \u043f\u043e 4k \u0438\u043b\u0438 \u0440\u0430\u043d\u0434\u043e\u043c\u043a\u0430 \u0437\u0430 14.8k"
            }, {
                "id": "b483d8ba-8245-49bd-8b70-618a70cf159e",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 10k \u0438\u043b\u0438 \u0440\u0430\u043d\u0434\u043e\u043c\u043a\u0430 \u0437\u0430 29.6k"
            }, {
                "id": "3fed8f27-7d93-4e71-b001-e0673a0cb065",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 40k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 50k"
            }, {
                "id": "ec278cc6-ca27-4d91-bc9b-078ce7312568",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 200 \u0438\u043b\u0438 100 spins \u043f\u043e 200 \u0441 \u0448\u0430\u043d\u0441\u043e\u043c. \u0414\u043e\u043f. \u0422\u041e\u041f BONUS \u0432\u044b\u0431\u0438\u0442\u0430\u044f \u0441 \u0440\u0443\u043a\u0438 \u0438\u043b\u0438 \u0432\u0438\u043b\u0434 \u044564 \u0432 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/104\/background-(14).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 17",
            "gridArea": "6 \/ 8",
            "position": "right"
        }, {
            "id": "sector_18",
            "tasks": [{
                "id": "8d2f54a6-c769-4f2b-b11d-8c9c4c6a545d",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 3.2k"
            }, {
                "id": "1c80ad20-193b-4eab-be19-7b7398b59471",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 9.6k"
            }, {
                "id": "67f8bf56-479a-44d2-8f94-e51c0688b9e8",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 48k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 48k"
            }, {
                "id": "b6c253aa-397b-4170-bd9d-91a07ab56865",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 130 spins \u043f\u043e 160. \u0414\u043e\u043f. \u0441\u044b\u0433\u0440\u043e\u0432\u043a\u0430 \u0411\u041e\u041c\u0411\u0410 \u04451000 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/105\/background-(15).webp",
            "modifier": null,
            "players": [],
            "text": "Sector 18",
            "gridArea": "7 \/ 8",
            "position": "right"
        }, {
            "id": "corner_4",
            "tasks": [],
            "image": "https:\/\/streamwidget.online\/storage\/90\/prison_bg.jpg",
            "players": [],
            "text": "Jail",
            "gridArea": "8 \/ 8",
            "position": "bottom-right"
        }, {
            "id": "sector_19",
            "tasks": [{
                "id": "ee21e066-cfeb-45a3-98fe-ecd6c94ba892",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 3.6k \u0438\u043b\u0438 2 \u0422\u041e\u041f BONUS \u043f\u043e 4.8k"
            }, {
                "id": "132776cd-513e-4c8a-ab83-a9b8228c63a7",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 9.6k \u0438\u043b\u0438 3 \u0422\u041e\u041f \u043f\u043e 9.6k"
            }, {
                "id": "127f33a0-5b95-4a4d-aa92-5488b2be6a60",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 27k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 24k"
            }, {
                "id": "e76824c1-8c1b-4667-ab10-bcf9ec733f34",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 130 spins \u043f\u043e 180. \u0414\u043e\u043f. 4 \u0448\u0442\u043e\u0440\u044b \u0437\u0430 spin \u0438\u043b\u0438 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/106\/background-(16).webp",
            "players": [],
            "text": "Sector 19",
            "gridArea": "8 \/ 7",
            "position": "bottom"
        }, {
            "id": "sector_20",
            "tasks": [{
                "id": "f74749e3-cbe8-4b81-b20c-340862e7003d",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 2 BONUS \u043f\u043e 4k  \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 10k"
            }, {
                "id": "5216475d-4a1b-4ae2-ac28-cdf90ca6bdb3",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 10k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u043f\u043e 30k"
            }, {
                "id": "15584ebf-2d23-47c6-822c-e0191b44615a",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 40k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 40k"
            }, {
                "id": "d4735056-fa2b-46b8-b5df-52e05f7074af",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 200 \u0438\u043b\u0438 125 spins \u0441 \u0448\u0430\u043d\u0441\u043e\u043c \u043f\u043e 180. \u0414\u043e\u043f. \u043c\u043e\u043d\u0435\u0442\u0430 \u0445500 \u0438\u043b\u0438 \u0437\u043e\u043b\u043e\u0442\u043e\u0439 \u043a\u043b\u0435\u0432\u0435\u0440 \u0437\u0430 \u0441\u043f\u0438\u043d \u0438\u043b\u0438 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/107\/background-(17).webp",
            "players": [],
            "text": "Sector 20",
            "gridArea": "8 \/ 6",
            "position": "bottom"
        }, {
            "id": "sector_21",
            "tasks": [{
                "id": "c7672890-1463-4bbc-a265-31395eab88a2",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c  2 BONUS \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 8k"
            }, {
                "id": "ab53c5ab-c02e-4946-be09-ccb6733d04e3",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 10k  \u0438\u043b\u0438 2 \u0422\u041e\u041f \u043f\u043e 16k"
            }, {
                "id": "bc2df092-11ad-440b-b5a7-4b91411e9114",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 40k \u0438\u043b\u0438 1 \u0422\u041e\u041f \u0437\u0430 48k"
            }, {
                "id": "693ffb6b-037a-42f7-aee5-ab5e95d594ec",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 200 \u0438\u043b\u0438 100 spins \u0441 \u0448\u0430\u043d\u0441\u043e\u043c \u043f\u043e 200. \u0414\u043e\u043f. 2 \u0440\u0435\u0442\u0442\u0440\u0438\u0433\u0435\u0440\u0430 \u0432 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/108\/background-(18).webp",
            "players": [],
            "text": "Sector 21",
            "gridArea": "8 \/ 5",
            "position": "bottom"
        }, {
            "id": "sector_22",
            "tasks": [{
                "id": "8d2f54a6-c769-4f2b-b11d-8c9c4c6a545d",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 3.2k"
            }, {
                "id": "1c80ad20-193b-4eab-be19-7b7398b59471",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 9.6k"
            }, {
                "id": "e28fd25e-10b5-4461-99d0-01c6aac161b5",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 40k"
            }, {
                "id": "a9a3250b-afa9-452d-b780-634e01fb943f",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 165 \u0438\u043b\u0438 \u0441 \u0448\u0430\u043d\u0441\u043e\u043c \u043f\u043e 200. \u0414\u043e\u043f. \u0441\u044b\u0433\u0440\u0430\u0432\u0448\u0430\u044f \u044550 \u0437\u0430 spin \u0438\u043b\u0438 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/109\/background-(19).webp",
            "players": [],
            "text": "Sector 22",
            "gridArea": "8 \/ 4",
            "position": "bottom"
        }, {
            "id": "sector_23",
            "tasks": [{
                "id": "fb3f3634-d3f5-4399-96a2-ed550e122e7f",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 2 BONUS \u043f\u043e 3.95k"
            }, {
                "id": "ade262f3-2649-4374-9673-7c75c7cc61c1",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 3 BONUS \u043f\u043e 7.9k"
            }, {
                "id": "c285707c-e44d-4c27-88fd-2f2221106318",
                "text": "\u041a\u0443\u043f\u0438\u0442\u044c 1 BONUS \u0437\u0430 39.5k"
            }, {
                "id": "d81a32c3-e2c1-4baf-86d2-b3c23ec02aba",
                "text": "\u041a\u0440\u0443\u0442\u0438\u0442\u044c 100 spins \u043f\u043e 250. \u0414\u043e\u043f. 8 \u0432\u0438\u043b\u0434\u043e\u0432 \u043d\u0430 \u043f\u043e\u043b\u0435 \u0437\u0430 spin \u0438\u043b\u0438 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0435 BONUS"
            }],
            "image": "https:\/\/streamwidget.online\/storage\/110\/background-(20).webp",
            "players": [],
            "text": "Sector 23",
            "gridArea": "8 \/ 3",
            "position": "bottom"
        }, {
            "id": "sector_24",
            "tasks": [],
            "image": "https:\/\/streamwidget.online\/storage\/85\/00_\u043d\u0430\u0437\u0430\u0434.jpg",
            "players": [],
            "text": "Sector 24",
            "gridArea": "8 \/ 2",
            "position": "bottom",
            "modifier": "-5"
        }]
export const defaultSlotopolyBoard1 =
    [
    {
        id: 'corner_1',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Start',
        gridArea: '8 / 1',
        position: 'bottom-left'
    },
    {
        id: 'sector_1',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 1',
        gridArea: '7 / 1',
        position: 'left',
    },
    {
        id: 'sector_2',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 2',
        gridArea: '6 / 1',
        position: 'left'
    },
    {
        id: 'sector_3',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 3',
        gridArea: '5 / 1',
        position: 'left'
    },
    {
        id: 'sector_4',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 4',
        gridArea: '4 / 1',
        position: 'left'
    },
    {
        id: 'sector_5',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 5',
        gridArea: '3 / 1',
        position: 'left'
    },
    {
        id: 'sector_6',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 6',
        gridArea: '2 / 1',
        position: 'left'
    },
    {
        id: 'corner_2',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Prize',
        gridArea: '1 / 1',
        position: 'top-left'
    },
    {
        id: 'sector_7',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 7',
        gridArea: '1 / 2',
        position: 'top'
    },
    {
        id: 'sector_8',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 8',
        gridArea: '1 / 3',
        position: 'top'
    },
    {
        id: 'sector_9',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 9',
        gridArea: '1 / 4',
        position: 'top'
    },
    {
        id: 'sector_10',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 10',
        gridArea: '1 / 5',
        position: 'top'
    },
    {
        id: 'sector_11',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 11',
        gridArea: '1 / 6',
        position: 'top'
    },
    {
        id: 'sector_12',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 12',
        gridArea: '1 / 7',
        position: 'top'
    },
    {
        id: 'corner_3',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Percent',
        gridArea: '1 / 8',
        position: 'top-right'
    },
    {
        id: 'sector_13',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 13',
        gridArea: '2 / 8',
        position: 'right'
    },
    {
        id: 'sector_14',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 14',
        gridArea: '3 / 8',
        position: 'right'
    },
    {
        id: 'sector_15',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 15',
        gridArea: '4 / 8',
        position: 'right'
    },
    {
        id: 'sector_16',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 16',
        gridArea: '5 / 8',
        position: 'right'
    },
    {
        id: 'sector_17',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 17',
        gridArea: '6 / 8',
        position: 'right'
    },
    {
        id: 'sector_18',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 18',
        gridArea: '7 / 8',
        position: 'right'
    },
    {
        id: 'corner_4',
        tasks: [],
        image: '',
        players: [],
        text: 'Jail',
        gridArea: '8 / 8',
        position: 'bottom-right'
    },
    {id: 'sector_19', tasks: [], image: '', players: [], text: 'Sector 19', gridArea: '8 / 7', position: 'bottom'},
    {id: 'sector_20', tasks: [], image: '', players: [], text: 'Sector 20', gridArea: '8 / 6', position: 'bottom'},
    {id: 'sector_21', tasks: [], image: '', players: [], text: 'Sector 21', gridArea: '8 / 5', position: 'bottom'},
    {id: 'sector_22', tasks: [], image: '', players: [], text: 'Sector 22', gridArea: '8 / 4', position: 'bottom'},
    {id: 'sector_23', tasks: [], image: '', players: [], text: 'Sector 23', gridArea: '8 / 3', position: 'bottom'},
    {id: 'sector_24', tasks: [], image: '', players: [], text: 'Sector 24', gridArea: '8 / 2', position: 'bottom'},
]

export const emptyPlayer = {
    id: uuidv4(),
    name: '',
    balance: 0,
    cooldown: 0,
    image: '',
    color: 'ff1488',
    active: false,
    out: false,
    position: 0,
    rollRandom: 0,
    roll: 0,
    activeTask: null
}

export const summMovements = (movements) => {
    return movements.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
    }, 0);
}