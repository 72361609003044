<script setup>
    import SlotopolyBoard from "@components/games/slotopoly/SlotopolyBoard.vue";
    import {computed} from "vue";

    const props = defineProps({
        gameData: {
            type: Object,
            required: true
        }
    })

    const game = computed(() => props.gameData?.meta);
</script>

<template>
    <div class="widget-wrapper">
        <slotopoly-board
                :game-data="game"
                :edit-mode="false"
                :is-widget="true"
        />
    </div>
</template>
<style scoped lang="scss">
    .widget-wrapper {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
</style>