<template>
    <div class="container">
        <div class="board-container">
            <div class="cords letters">
                <div class="letter"
                     v-for="l in numbersCords"
                     :key="l">
                    {{ l }}
                </div>
            </div>
            <div class="cords numbers">
                <div class="number"
                     v-for="n in lettersCords"
                     :key="n">
                    {{ n }}
                </div>
            </div>
            <div class="board">
                <div v-for="(row, rowIndex) in board"
                     :key="rowIndex"
                     class="row">
                    <div v-for="(cell, cellIndex) in row"
                         :key="cellIndex"
                         class="spot"
                         :class="getCellClass(cell,  rowIndex, cellIndex)">
                        {{ cell }}
                    </div>
                </div>
            </div>

        </div>
        <div class="stats" v-if="showStats">
            <div class="shoots">
                <span>hit: {{ shootsStats.hit }}</span>
                <span>miss: {{ shootsStats.miss }}</span>
            </div>
            <div class="ships">
                <div>
                    <div class="ship-icon">
                        {{ remainingShips[1] || 0 }} :
                        <div class="info-ship">
                            <div class="info-spot"/>
                        </div>
                    </div>

                    <div class="ship-icon">
                        {{ remainingShips[2] || 0 }} :
                        <div class="info-ship">
                            <div v-for="i2 in 2"
                                 :key="i2"
                                 class="info-spot"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="ship-icon">
                        {{ remainingShips[3] || 0 }} :
                        <div class="info-ship">
                            <div v-for="i3 in 3"
                                 :key="i3"
                                 class="info-spot"/>
                        </div>
                    </div>

                    <div class="ship-icon">
                        {{ remainingShips[4] || 0 }} :
                        <div class="info-ship">
                            <div v-for="i4 in 4"
                                 :key="i4"
                                 class="info-spot"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        name: 'SeaBattleWidget',

        props: {
            gameData: {
                type: Object
            },
            showStats: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                remainingShips: {
                    1: 4,
                    2: 3,
                    3: 2,
                    4: 1
                }
            }
        },
        computed: {
            allShipsSunk() {
                return !!this.shootsStats
                    && !!this.gameData.meta.shootData
                    && (this.remainingShips[1] === 0
                        && this.remainingShips[2] === 0
                        && this.remainingShips[3] === 0
                        && this.remainingShips[4] === 0)
            },

            lettersCords() {
                return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
            },

            numbersCords() {
                return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            },

            defaultBoard() {
                return [
                    ['~', '~', '~', '~', '~', '~', '~', '~', '~', '~'],
                    ['~', '~', '~', '~', '~', '~', '~', '~', '~', '~'],
                    ['~', '~', '~', '~', '~', '~', '~', '~', '~', '~'],
                    ['~', '~', '~', '~', '~', '~', '~', '~', '~', '~'],
                    ['~', '~', '~', '~', '~', '~', '~', '~', '~', '~'],
                    ['~', '~', '~', '~', '~', '~', '~', '~', '~', '~'],
                    ['~', '~', '~', '~', '~', '~', '~', '~', '~', '~'],
                    ['~', '~', '~', '~', '~', '~', '~', '~', '~', '~'],
                    ['~', '~', '~', '~', '~', '~', '~', '~', '~', '~'],
                    ['~', '~', '~', '~', '~', '~', '~', '~', '~', '~'],
                ];
            },

            board() {
                return this.gameData?.meta?.board || this.defaultBoard
            },

            lastShoot() {
                return this.gameData?.meta?.lastShoot || {x: null, y: null}
            },
            shootsStats() {
                return this.gameData?.meta?.shootData?.stats || {hit: 0, miss: 0}
            }
        },
        watch: {
            shootsStats: {
                handler(newVal, oldVal) {
                    this.updateRemainingShips();
                },
                deep: true,
                immediate: true
            }
        },
        methods: {
            checkIfSunk(ship) {
                return ship.every(part => this.board[part.y][part.x] === 'X');
            },

            updateRemainingShips() {
                const newRemainingShips = {1: 0, 2: 0, 3: 0, 4: 0};

                const ships = this.gameData?.meta?.startPositions?.ships || [];
                ships.forEach(ship => {
                    if (!this.checkIfSunk(ship)) {
                        newRemainingShips[ship.length]++;
                    }
                });

                this.remainingShips = newRemainingShips;
            },

            getCellClass(cell, rowIndex, cellIndex) {
                const isLastShoot = this.lastShoot.x === cellIndex && this.lastShoot.y === rowIndex
                if (cell === 'x') {
                    return `hit ${isLastShoot ? 'last-shoot' : ''}`
                } else if (cell === 'X') {
                    return `killed ${isLastShoot ? 'last-shoot' : ''}`
                } else if (cell === '*') {
                    return `miss ${isLastShoot ? 'last-shoot' : ''}`
                } else {
                    return '';
                }
            },
        },

    }
</script>

<style lang="scss" scoped>
    .container {
        --spot-size: 2rem;
        display: flex;
        flex-direction: column;
        max-width: min-content;
        gap: 1rem;
        padding: var(--spot-size);
        background-color: white;
    }

    .cords {
        display: grid;
        text-shadow: 0 2px 2px black;
        font-size: calc(var(--spot-size) * 1.5);
        justify-items: end;
        gap: calc(var(--spot-size) * 0.25);
    }

    .letters {
        justify-content: space-evenly;
        grid-template-columns: repeat(10, var(--spot-size));
        grid-column: 2 / 3;
    }

    .numbers {
        grid-template-rows: repeat(10, var(--spot-size));
        align-content: space-evenly;
    }

    .letter,
    .number {
        width: var(--spot-size);
        height: var(--spot-size);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .board-container {
        display: grid;
        grid-template: var(--spot-size) 1fr / var(--spot-size) 1fr;

        position: relative;

    }

    .board {
        display: grid;
        border: calc(var(--spot-size) * 0.25) solid rgb(0, 44, 102);
        background-color: rgb(0, 44, 102);
    }

    .row {
        display: flex;
    }

    .spot {
        width: calc(var(--spot-size) * 2);
        height: calc(var(--spot-size) * 2);
        background-color: white;
        position: relative;
        font-size: 2rem;
        text-decoration: double;
        color: white;
        border: 3px solid black;
        display: flex;
        justify-content: center;
        align-items: center;

        &.last-shoot {
            border: 4px solid yellow;
        }

        &.ship {
            background-color: grey;
            color: grey;
        }

        &.hit {
            background-color: pink;
            color: pink;

            &.last-shoot {
                border: 4px solid yellow;
            }
        }

        &.killed {
            background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='white' stroke-width='4'/><path d='M0 0 L100 100 ' stroke='white' stroke-width='4'/></svg>");
            background-repeat: no-repeat;
            background-position: center center;
            color: red;
            font-size: 2px;
            background-color: red;
            box-shadow: inset 0px 0px 3px 3px white;
            background-size: 100% 100%, auto;

            &.last-shoot {
                box-shadow: inset 0px 0px 3px 3px yellow;
            }
        }

        &.miss {
            background-color: rgb(35, 137, 218);
            color: white;
        }


        &:hover {
            box-shadow: inset 1px 1px 3px 3px greenyellow;
            cursor: crosshair;
        }


    }

    .stats {
        padding: var(--spot-size) 0 0 var(--spot-size);
        font-size: 2rem;
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        width: 100%;
    }

    .shoots {
        display: flex;
        flex-direction: column;
    }

    .ships {
        display: flex;
        gap: 1rem;

        .ship-icon {
            display: flex;
            gap: 8px;
            align-items: center;

            .info-ship {
                display: flex;

                .info-spot {
                    width: var(--spot-size);
                    height: var(--spot-size);
                    border: 2px solid black;
                }
            }
        }
    }

    .finish-alert {
        z-index: 2;

        .overlay {
            backdrop-filter: blur(5px);
            z-index: 3;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
        }

        .alert-content {
            width: calc(var(--spot-size) * 10);
            height: calc(var(--spot-size) * 8);
            position: absolute;
            top: 37%;
            left: 26%;
            transform: translate(-50%, -50%);
            background: white;
            z-index: 4;
            display: flex;
            border-radius: 10px;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            text-align: center;
            border: 4px solid black;
        }
    }
</style>

