<script setup lang="ts">
    import {computed, ref} from "vue";
    import Button from "primevue/button";
    import InputText from "primevue/inputtext";
    import RadioButton from "primevue/radiobutton";
    import PlayerChip from "@components/games/slotopoly/PlayerChip.vue";
    import {v4 as uuidv4} from 'uuid'

    const props = defineProps(['modelValue', 'sectors', 'editMode', 'movements']);
    const emit = defineEmits(["update:modelValue", 'player:move', 'update:movements', 'superGame']);

    const localPlayers = ref(props.modelValue);

    const BOARD_SIZE = 28
    const ANIMATION_DELAY = 2_000

    const players = computed({
        get: () => props.modelValue,
        set: (value) => {
            localPlayers.value = value;
            emit("update:modelValue", localPlayers.value);
        },
    });

    const activePlayerIndex = ref(0)

    const animationOngoing = ref(false)

    const setAnimationDelay = (delay = ANIMATION_DELAY) => {
        animationOngoing.value = true
        setTimeout(() => {
            animationOngoing.value = false
        }, delay)
    }

    const isActivePlayerOnModSector = computed(() => {
        const activePlayerPosition = players.value[activePlayerIndex.value]?.position
        const sector = props.sectors[activePlayerPosition]
        return sector?.modifier ?? null
    })

    const setJail = (index: number) => {
        if (players.value[index]?.cooldown > 0) {
            players.value[index].cooldown--
        } else players.value[index].cooldown = 2;
        emit('update:modelValue');
    };

    const deactivate = index => players.value[index].out = !players.value[index].out


    const setActivePlayer = (index: number) => {
        players.value.forEach((player) => player.active = false);
        players.value[index].active = true;
        emit("update:modelValue", localPlayers.value);
    };

    const setModifierValue = (index: number) => {
        if (isActivePlayerOnModSector.value) {
            const modifier = parseInt(isActivePlayerOnModSector.value, 10); // Преобразуем строку в число
            if (!isNaN(modifier)) {
                players.value[index].roll = modifier;
            } else {
                players.value[index].roll = 0
                console.warn("Invalid modifier:", isActivePlayerOnModSector.value);
            }
        }
    }
    const addMovement = (playerId, movement, random) => {
        setAnimationDelay()
        const newMovements = [...props.movements]
        newMovements.push({
            id: uuidv4(),
            playerId: playerId,
            movement: movement,
            random: random
        })

        emit('update:movements', newMovements)
    }

    const dropWinnerTable = () => {
        const newMovements = [...props.movements]
        newMovements.push({
            id: uuidv4(),
            dropWinner: true,
        })

        emit('update:movements', newMovements)
    }

    const rollBackMovement = () => {
        setAnimationDelay(2000)
        const lastMovement = props.movements.at(-1);
        if (!lastMovement.dropWinner) {
            const playerIndex = players.value.findIndex(player => player.id === lastMovement.playerId)
            if (players.value[playerIndex]?.position) players.value[playerIndex].position -= lastMovement.movement
        }
        const newMovements = props.movements.slice(0, -1);
        emit('update:movements', newMovements)
    }

    const setDiceRandom = (index: number) => {
        players.value[index].roll = 0

        const random = Math.floor(Math.random() * 6) + 1;
        players.value[index].rollRandom = random;
        players.value[index].position += random


        const playerId = players.value[index].id
        addMovement(playerId, random, true)

        setModifierValue(index)
    };

    const setDiceManual = (index: number) => {
        players.value[index].rollRandom = 0

        players.value[index].position += players.value[index].roll

        const playerId = players.value[index].id
        addMovement(playerId, players.value[index].roll, false)

        players.value[index].roll = 0
        setModifierValue(index)
    }

    const whereAreWinner = computed(() => {
        if (!!players.value?.length) return players.value.some(player => player.position >= BOARD_SIZE)
    })

    const startSuperGame = () => {
        emit('superGame')
    }
</script>

<template>
    <div class="players">
        <div v-for="(player, index) in players" :key="index" class="player"
             :class="player.deactivated ? 'deactivated' : ''">
            <RadioButton v-model='activePlayerIndex' :value="index" @change="setActivePlayer(index)"
                         :disabled="editMode || animationOngoing"/>

            <player-chip :player="player"/>

            <span>{{ player.name }}</span>

            <InputText
                    v-model.number="player.balance"
                    :placeholder="player.balance || 'Баланс'"
                    class="p-inputtext-sm"
                    type="number"
                    min="1"
                    max="6"
                    :disabled="player.cooldown > 0 || player.out || editMode || index !== activePlayerIndex || animationOngoing"
            />

            <Button
                    icon="pi pi-hashtag"
                    class="p-button-sm"
                    @click="setJail(index)"
                    :label="player.cooldown > 0 ? `cd ${player.cooldown}` : 'Тюрьма'"
                    :disabled="player.out || editMode || animationOngoing"
            />

            <Button
                    :icon="!player.out ? 'pi pi-eraser' : 'pi pi-history'"
                    class="p-button-sm"
                    @click="deactivate(index)"
                    :label="player.out ? 'Вернуть' : 'Выбыл'"
                    :disabled="editMode || index !== activePlayerIndex || animationOngoing"
            />
            <Button
                    icon="pi pi-microsoft"
                    class="p-button-sm"
                    @click="setDiceRandom(index)"
                    label="Рандом"
                    :disabled="player.cooldown > 0 || player.out || editMode || index !== activePlayerIndex || !!player.roll || animationOngoing"
            />

            <InputText
                    v-model.number="player.roll"
                    :placeholder="player.roll || 'Кубик'"
                    class="p-inputtext-sm"
                    type="number"
                    min="-6"
                    max="6"
                    :disabled="player.cooldown > 0 || player.out || editMode || index !== activePlayerIndex"
            />

            <Button
                    icon="pi pi-arrow-right"
                    class="p-button-sm"
                    @click="setDiceManual(index)"
                    label="Сделать Ход(вручную)"
                    :disabled="!player.roll || player.cooldown > 0 || editMode || index !== activePlayerIndex || animationOngoing"
            />
        </div>
        <div class="buttons flex gap-2">
            <Button icon="pi pi-undo" class="p-button-sm" @click="rollBackMovement" label="Отменить ход"
                    :disabled="!movements?.length || editMode || animationOngoing"/>
            <Button v-if="whereAreWinner" class="p-button-sm" @click="startSuperGame" label="Супер-игра"/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .players {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: start;
    }

    .player {
        display: grid;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        width: 100%;
        grid-template-columns: 32px 32px 100px 150px 150px 100px 150px 100px 250px;

        &.deactivated {
            filter: grayscale(100%);
        }
    }
</style>
