<template>
    <div :class="['video-player',{ 'video-player--active': isPlaying }]">
        <video
                v-for="(video, index) in videos"
                :key="index"
                :ref="el => videoRefs[index] = el"
                :src="video.src"
                preload="auto"
                muted="muted"
                :class="{ 'visible': currentVideoIndex === index }"
                @ended="handleVideoEnd"
        />
    </div>
</template>

<script setup>
    import {ref} from 'vue';

    const props = defineProps({
        videos: {
            type: Array,
            required: true,
        }
    });
    const emit = defineEmits(['playbackComplete']);

    const videoRefs = ref([]);
    const isPlaying = ref(false);
    const currentVideoIndex = ref(-1);
    let currentResolve = null;

    const playVideo = async (index) => {
        if (index < 0 || index >= props.videos.length) {
            throw new Error('Invalid video index');
        }

        // Остановим текущее видео если оно проигрывается
        if (currentVideoIndex.value !== -1) {
            videoRefs.value[currentVideoIndex.value].pause();
            videoRefs.value[currentVideoIndex.value].currentTime = 0;
        }

        return new Promise((resolve) => {
            currentResolve = resolve;
            currentVideoIndex.value = index;
            isPlaying.value = true;

            const video = videoRefs.value[index];
            video.play();
        });
    };

    const handleVideoEnd = () => {
        isPlaying.value = false;
        currentVideoIndex.value = -1;
        if (currentResolve) {
            currentResolve();
            currentResolve = null;
        }
        emit('playbackComplete');
    };

    defineExpose({
        playVideo
    });
</script>

<style scoped lang="scss">
    .video-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        z-index: -1;
        transition: opacity 0.7s ease-out;

        &--active {
            opacity: 1;
            z-index: 10;
            pointer-events: auto;
            transition: opacity 0.7s ease-in;
        }


        video {
            //position: relative;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: none;

            &.visible {
                display: block;
            }
        }

    }


</style>