<template>
    <div class="session-widget">
        <div class="session-widget-header text-big">
            <div class="title">
                {{ titleTranslate(session.title) }}
            </div>
            <div class="currency">
                {{ session.currency }}
            </div>
        </div>

        <div class="widget-session-stats text-middle">

            <div class="widget-block-small">
             <span class="title">
                 <slot name="title1">
              БОНУС
                </slot>
                 </span>
                <span class="value">
                {{ session.bonusQty }}
            </span>
            </div>

            <div class="widget-block-small middle">
            <span class="title">
                <slot name="title2">
                    ВИН/ДЕП
                </slot>
            </span>
                <span class="value">
                {{ session.winDepRatio }}
            </span>
            </div>

            <div class="widget-block-small">
            <span class="title">
                <slot name="title3">
                    СРД X
                </slot>
            </span>
                <span class="value">
                {{ session.formattedAvgX }}
            </span>
            </div>
        </div>


        <div class="session-widget-leaders text-small">
            <div class="session-widget-leaders-row" v-show="leaders.leaderWin.show">
                <span class="icon" >🏅</span>
                <span class="title">{{ leaders.leaderWin.title }}</span>
                <span class="bet">{{ leaders.leaderWin.bet }}</span>
                <span class="bet">{{ leaders.leaderWin.x }}</span>
                <span class="win">{{ leaders.leaderWin.win }}</span>
            </div>
        </div>
        <div>
        <div class="session-widget-slots-table-header">
            <span class="slot">СЛОТ</span>
            <span class="bet">СТАВКА</span>
            <span class="bet">X</span>
            <span class="win">ВЫИГРЫШ</span>
        </div>
        <div
            class="session-widget-slots-table text-small"
            ref="slots-table">
            <div class="widget-table-row"
                 v-for="(slot) in slots"
                 :key="slot.order"
                 :class="setActive(slot)"
                 :ref="setActive(slot)">
                <span class="serial text-number">{{ slot.order }}</span>
                <span class="title">{{ slot.title }}</span>
                <span class="bet text-number">{{ slot.bet }}</span>
                <span class="x text-number">{{ slot.x | fixDecimal}}</span>
                <span class="win text-number">{{ slot.win }}</span>
            </div>
        </div>
        </div>


    </div>
</template>
<script>
    export default {
        name: 'SessionWidgetAks',
        props: {
            showLosers: {
                type: Boolean,
                default: false,
            },
            session: {
                type: Object,
            },
            leaders: {
                type: Object,
            },
            slots: {
                type: Array,
            },
        },
        watch: {
            slots: {
                handler() {
                    if (!!this.slots.length) {
                        this.scrollToActiveSlot()
                    }
                },
                deep: true,
            },
        },

        methods: {
            titleTranslate(title) {
                const replacements = {
                    'BONUS BUY': 'БОНУС БАЙ',
                    'BONUS HUNT': 'БОНУС ХАНТ',
                    'BONUS TOURNAMENT': 'БОНУС ТУРНИР',
                }

                const pattern = new RegExp(Object.keys(replacements).join('|'), 'g')

                return title.replace(pattern, match => replacements[match])
            },

            setActive(slot) {
                return slot.is_active === 1 ? 'active' : 'listItem'
            },

            scrollToActiveSlot() {
                this.$nextTick(() => {
                    const targetElement = this.$refs.active[0]
                    if (targetElement && targetElement.scrollIntoView) {
                        targetElement.scrollIntoView({behavior: 'smooth', block: 'center'})
                    }
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../assets/css/variables';

    .session-widget {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        color: white;
        background-color: #010D11;
        border-radius: 5%;
        cursor: none;
        gap: $container-padding;
        flex: 0 1 auto;
        overflow: hidden
    }

    .session-widget-header {
        display: flex;
        height: 12.5%;
        flex-wrap: nowrap;
        justify-content: space-between;
        background: linear-gradient(89.03deg, #008958 -3.87%, #3B0069 106.39%);
        align-items: center;
        padding: 0.5em;
        border-radius: 0 0 0.5em 0.5em;
    }

    .logo, .currency {
        background: #00000033;
        border-radius: 0.25em;
        padding: 0 0.25em;
    }

    .logo {
        height: 100%;
        width: 33%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25em;
         > * {
            //background-image: url("./streamer-aks-logo.png");
             width: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-clip: padding-box;
            background-size: contain;
        }
    }


    .widget-session-stats {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 0.3em !important;
        gap: $section-padding;
    }

    .widget-block-small {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0.3em 0.3em;
        overflow: hidden;

        &.middle {
            width: 50%;
        }

        .title {
            background: linear-gradient(269.28deg, #08AA70 4.14%, #780DCD 113.19%);
            background-clip: border-box;
            width: 100%;
            height: 40%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            font-size: calc($small-font-size * .75) !important;
        }

        .value {
            background: linear-gradient(269.28deg, rgba(8, 170, 112, 0.15) 4.14%, rgba(120, 13, 205, 0.15) 113.19%);
            background-clip: border-box;
            width: 100%;
            height: 60%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
    }


    $slots-rows-gap: calc($section-padding * 0.75);
    $active-slot-font-size: calc($middle-font-size * 1.2);

    .session-widget-leaders {
        min-height: calc(3 * $middle-font-size + calc(2 * $slots-rows-gap));
        display: flex;
        gap: $container-padding;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        background: linear-gradient(269.28deg, rgba(8, 170, 112, 0.15) 4.14%, rgba(120, 13, 205, 0.15) 113.19%);
        margin: 0 0.3em !important;
        padding: $section-padding;
        border-radius: 1em;
    }

    .session-widget-leaders-row {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .icon {
            width: 5%;
            text-align: start;
            margin-right: 0.25em;
        }

        .title {
            width: 42%;
            text-align: start;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .bet {
            width: 25%;
            text-align: center;
        }

        .win {
            width: 25%;
            text-align: end;
        }
    }


    .session-widget-slots-table {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        gap: $slots-rows-gap;
        height: calc(7 * $middle-font-size + calc($active-slot-font-size * 1.1) + calc(6 * $slots-rows-gap) + calc(4 * $section-padding));
        flex: 1 1 auto;
        overflow: hidden;
        background: linear-gradient(269.28deg, rgba(8, 170, 112, 0.15) 4.14%, rgba(120, 13, 205, 0.15) 113.19%);
        //border-radius: 1em;
    }

    .session-widget-slots-table-header {
        background: linear-gradient(269.28deg, rgba(8, 170, 112) 4.14%, rgba(120, 13, 205) 113.19%);
        background-clip: border-box;
        display: flex;
        padding: 0 0.5em;
        width: 100%;
        font-size: calc($small-font-size * .75) !important;
        border-radius: 1em 1em 0 0;

        .slot {
            width: 50%;
            display: flex;
            justify-content: center;
        }

        .bet {
            width: 25%;
            display: flex;
            justify-content: center;
        }

        .win {
            width: 25%;
            display: flex;
            justify-content: center;
        }

    }

    ::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    .widget-table-row {
        display: flex;
        flex-wrap: nowrap !important;
        flex-direction: row;
        justify-content: space-between;
        white-space: nowrap;
        gap: 3px;
        width: 100%;
        padding: 0 0.5em;
        opacity: 0.8;
        font-size: $small-font-size !important;

        .serial {
            text-align: start;
        }

        .title {
            width: 45%;
            text-align: start;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .bet {
            width: 25%;
            text-align: center;
        }

        .x {
            width: 25%;
            text-align: center;
            color:#BDBDBD;
        }

        .win {
            width: 25%;
            text-align: end;
            padding-right: 0.5em;
        }


        &.active {
            align-items: center;
            font-size: calc($small-font-size * 1.2) !important;
            padding: 0 0.8em;
            transition: transform 0.3s ease;
            transform: scale(1.1);
            width: 94%;
            opacity: 1;
            background: linear-gradient(269.28deg, rgba(8, 170, 112) 4.14%, rgba(120, 13, 205) 113.19%);
            background-clip: border-box;

            :deep .title {
                width: 46%;
            }
        }
    }
    .session-widget {
        font-family: Montserrat, serif
    }
</style>
