<template>
    <div class="p-2">
        <div class="controls flex flex-column w-100">
            <div class="flex gap-2 mb-2">
                <Button class="p-button p-button-sm" @click="createEmptyGame(props.gameType)">create {{ gameTitle }}
                </Button>
                <Button class="p-button p-button-sm" @click="finishGame">finish game</Button>
                <Button class="p-button p-button-sm" @click="getActiveGame">get previous unfinished game</Button>
                <!--                <Button class="p-button p-button-sm" @click="getGamesList">games list</Button>-->

            </div>
            <div v-if="!!id" class="flex flex-column mb-2">
                <div >
                    <span class="text-big">widget link: </span>
                    <span class="text-middle">{{ widgetLink }}</span>
                </div>
                <div  v-if="gameType === 'slotopoly'">
                    <span class="text-big">small widget link: </span>
                    <span class="text-middle">
                        {{ SlotopolySmallWidgetLink }}
                    </span>
                </div>

            </div>

            <br/>
        </div>
        <div v-show="gameCreated">
            <component :is="whichGame"
                       ref="gameWrapper"
                       @game:update="updateGame"
                       @game:finish="finishGame"/>
        </div>

        <!--        <div class="list">-->
        <!--            <div v-for="game in gamesLIst" :key="game.id">-->
        <!--                {{game}}-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script setup>
    import {computed, ref} from "vue";
    import {useStore} from "vuex";

    import SeaBattleIndex from "@pages/games/sea-battle/SeaBattleIndex.vue";
    import SlotopolyIndex from "@pages/games/slotopoly/SlotopolyIndex.vue";
    import Button from 'primevue/button';

    import {defaultSlotopolyBoard, emptyPlayer} from "@components/games/slotopoly/defaultSlotopolyEntries.js";

    const props = defineProps({
        gameType: 'sea_battle' | 'slotopoly'
    })

    const store = useStore();
    const gameCreated = ref(false)
    const gameWrapper = ref(null);

    const whichGame = computed(() => {
        if (props.gameType === 'sea_battle') return SeaBattleIndex
        if (props.gameType === 'slotopoly') return SlotopolyIndex
    });

    const gameTitle = computed(() => {
        if (props.gameType === 'sea_battle') return 'Sea Battle'
        if (props.gameType === 'slotopoly') return 'Slotopoly'
    })

    const activeGame = computed(() => store.getters['games/activeGame']);
    const id = computed(() => activeGame.value?.id);

    const appUrl = import.meta.env.VITE_APP_URL ?? 'https://casinowidget.pro';
    const publicToken = computed(() => store.getters['user/me'].public_token);
    const widgetLink = computed(() => appUrl + `/game-widget?public_token=${publicToken.value}`)
    const SlotopolySmallWidgetLink = computed(() => appUrl + `/slotopoly-extra?public_token=${publicToken.value}`)


    // const gamesLIst = computed(() => store.getters['games/games']);

    const emptyGameData = computed(() => {
        switch (props.gameType) {
            case 'sea_battle':
                return {justCreated: true}
            case 'slotopoly':
                return {
                    board: defaultSlotopolyBoard,
                    players: [{
                        ...emptyPlayer,
                        active: true
                    }],
                    lastMove: {},
                    tasks: {},
                    movements: []
                }
        }
    });


    const createEmptyGame = async (type) => {
        store.commit('games/deleteActiveGame')
        gameWrapper.value.clearGameData()
        await store.dispatch('games/createGame', {type: type, meta: emptyGameData.value});
        gameCreated.value = true
    }

    const updateGame = async (gameData) => {
        if (!id.value) {
            await createEmptyGame(props.gameType)
        }
        await store.dispatch('games/updateGame', ({game_id: id.value, payload: {meta: gameData}}))
        // gameWrapper.value.setActiveGame()
    };

    const getActiveGame = async () => {
        await store.dispatch('games/getActiveGame')
        gameWrapper.value.setActiveGame()
        gameCreated.value = true
    };

    const finishGame = () => {
        gameWrapper.value.finishGame();
        store.dispatch('games/finishGame', id.value);
    };

    const getGamesList = () => {
        store.dispatch('games/getGamesList')
    }
</script>
