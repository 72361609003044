<template>
  <div class="widget-page">
      <SlotWidget v-if="available" class="slot-widget"/>
      <no-permission v-else :type="'Slot Widget'"/>
  </div>
</template>

<script>
  import SlotWidget from '@widgets/slot-statistics-widget/SlotStatsWidgetWrapper.vue'
  import NoPermission from '@widgets/NoPermission.vue'

  export default {
    name: 'SlotWidgetSeparatedPage',

    components: {
        NoPermission,
      SlotWidget,
    },

    data() {
      return {
        ready: false,
        reFetchDelay: parseInt(process.env.MIX_WIDGET_REFETCH_DELAY) || 2000,
      }
    },

    async mounted() {
      await this.setToken()
      await this.fetchSettings()
        if(this.available) {
            await this.cyclicUpdateSlot()
            this.ready = true
        }
    },

      computed: {
        available() {
            return this.$tariffs.hasModule('SlotStats')
        }
      },

    methods: {
      setToken() {
        localStorage.setItem('publicToken', this.$route.query.public_token)
      },

      async fetchSettings() {
        await this.$store.dispatch('settings/fetchWidgetSettings')
      },

      cyclicUpdateSlot() {
        setTimeout(() => {
          this.$store
              .dispatch('widgetSlot/getPublicSelectedSlot')
              .then(this.cyclicUpdateSlot())
        }, this.reFetchDelay)
      },
    },
  }
</script>

